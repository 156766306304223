import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Popup, CircleMarker, Polyline} from 'react-leaflet'
import CircularProgress from '@mui/material/CircularProgress';
import {Typography, Grid, Divider} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CustomAlert from './Alert';
import Map from './Map';
import L from 'leaflet';


export default function Details({token}){
    const [layers, setLayers] = useState([]);
    const {imo} = useParams();
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState(undefined);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [data, setData] = useState(undefined);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const fieldErrorTemplate = {
        "imo": undefined,
        "ship_name": undefined,
        "customer_id": undefined,
        "customer_name": undefined,
        "supervisor": undefined,
        "purchasing": undefined,
        "logistics": undefined,
        "start_date": undefined,
        "end_date": undefined,
        "user_name": undefined,
        "dwt": undefined,
        "ship_type": undefined,
        "operating_region": undefined,
        "lat": undefined,
        "lng": 5.22316166666667,
        "speed": undefined,
        "destinations": []
    };
    const [fieldErrors , setFieldErrors] = useState(fieldErrorTemplate);


    

      const legendContent = () => {
        let div = L.DomUtil.create('div', 'cirlces legend'), labels=['Location(Moving)', 'Location(Stopped)', 'Current Port', 'Future Destination'];
        let color = '#FFC000';
        div.innerHTML+="<strong style='font-size:1.2rem'></strong></br>"
        div.style = 'text-align:left';
        labels.forEach((label) => {
            if(label === 'Location(Moving)'){
                color = 'black';
            }
            else if(label === 'Location(Stopped)'){
                color = 'red';
            }
            else if(label === 'Future Destination'){
                color = '#00C000';
            }
            else{

                color = '#4D93D9';
            }
            div.innerHTML += `<i style="min-width:20px; background: ${color};width: 16px; height: 16px; display: inline-block"></i><div style="font-size:1.1rem;display:inline;min-width:20px;text-align:left">  ${label}</div><br>`;
        });
        return div;
    }
    const handelUpdate=()=>{
        updateDetails(data);
    }
    function updateDetails(currentData){
        setSuccess(false);
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/ship/${imo}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(currentData)
            }
        )
        .then(async response => {
            if(response.status === 400){
                return await response.text().then(text => {
                    const parsedErrors = JSON.parse(text);
                    const newFieldErrors = { ...fieldErrors }; // Start with existing state
            
                    // Populate newFieldErrors with parsed error messages
                    Object.keys(parsedErrors).forEach((key) => {
                        newFieldErrors[key] = parsedErrors[key][0]; // Assign the first error message for each field
                    });
            
                    // Update the state once after the loop
                    setFieldErrors(newFieldErrors);
                    console.log(newFieldErrors); // Log the updated errors for debugging
            
                    throw new Error('Bad request');
                });
            }
            else{
                return response.json();
            }
        })
        .then(data => {
            setData(data);
            setSuccess(true);
            setSuccessMessage('Details updated successfully');
            setFieldErrors(fieldErrorTemplate);
        })
        .catch(error => {
            console.log(error);
            setSuccess(false);
        });
    }
    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/locations/?d=y`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_name}
            }) 
            setLocations(newData);
            // set a blank location
            setLocations([...newData, {label: '', id: ''}]);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }, [token]);

    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/ship/${imo}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
        )
        .then(response => {
            // check for 404
            if(response.status === 404){
                setError(true);
                setErrorMessage('Ship not found');
            }else if(response.status === 500){
                setError(true);
                setErrorMessage('Internal server error');
            }
            return response.json();
        })
        .then(data => {
            if(data.lat === null){
                data.lat = undefined;
            }
            if(data.lng === null){
                data.lng = undefined;
            }
            setError(false);
            setErrorMessage('');
            setData(data);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
        });
    }, [imo, token]);

    const getLineSegments = (points) => {
        const segments = [];
        let currentSegment = [];
      
        points.forEach((point) => {
          if (point.lat === "BLANK" || point.lng === "BLANK") {
            // Push the current segment if it's not empty
            if (currentSegment.length > 1) {
              segments.push([...currentSegment]);
            }
            // Reset the current segment
            currentSegment = [];
          } else {
            // Add valid points to the current segment
            currentSegment.push([point.lat, point.lng]);
          }
        });
      
        // Push the last segment if it's valid and not empty
        if (currentSegment.length > 1) {
          segments.push(currentSegment);
        }
      
        return segments;
    };

    useEffect(() => {
        const layersArray = [];
        if(data){
            const shipStateData = data;
            const accumalatedLines = getLineSegments(shipStateData.lines);
            if(Object.keys(shipStateData).length > 0 && shipStateData.lat  && shipStateData.lng){
                layersArray.push(
                        <React.Fragment key={`${shipStateData.imo}`}>
                            <CircleMarker key={`${shipStateData.imo}-circle1`} center={[shipStateData.lat, shipStateData.lng]} pathOptions={{color:"black", fillColor: "black", fillOpacity: 1}} zoom={2} radius={5}/>
                        </React.Fragment>
                );
            }
            shipStateData.destinations.forEach((destination, index) => {
                layersArray.push([
                    <React.Fragment key={`${shipStateData.imo}-destination`}>
                       <CircleMarker key={`${shipStateData.imo}-destination-circle`} center={[destination.lat, destination.lng]} pathOptions={{color:destination.colour, fillColor: destination.colour, fillOpacity: 1}} radius={5}>
                            <Popup>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography component="p" variant="p">{destination.label}</Typography>
                                    </Grid>
                                </Grid>
                            </Popup>

                    </CircleMarker>
                  </React.Fragment>
                ])
            })
            layersArray.push(
                <React.Fragment key={"line-fragment"}>
                    <Polyline key={"lines"} pathOptions={{ color: 'green' }} positions={accumalatedLines}/>
                </React.Fragment>
            )
        }
        setLayers(layersArray);
        setLoading(false);
      }
      , [data]);


    return (
        <Grid item xs={12} style={{ marginTop: '5px', marginBottom:'5%'}}>
            {loading  ? (<CircularProgress />) : (
            <>
                {error ? <CustomAlert message={errorMessage} severity="error"/>:
                
                <>
                    <Grid item xs={12} sm={12} sx={{marginTop:'2px'}}>
                        {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6} sx={{display:{sm:'none', xs:'none',md:'flex',lg:'flex'}, marginTop:'20px'}}>
                            {layers  && data && data.lat && data.lng ? <Map height={'910px'} zoom={6} layers={layers} mapCenterData={[{"lat":data.lat,"lng":data.lng}]} legendContent={legendContent}/>:null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginTop:'10px'}}>
                                <TextField
                                    required
                                    id="description"
                                    value={data?data.customer_name:''}
                                    onChange={(event) => setData({...data, customer_name: event.target.value})}
                                    fullWidth
                                    margin='normal'
                                    label="Customer"
                            
                                    /> 
                                    <TextField
                                    required
                                    id="supervisor"
                                    value={data?data.supervisor:''}
                                    onChange={(event) => setData({...data, supervisor: event.target.value})}
                                    fullWidth
                                    margin='normal'
                                    label="Supervisor"
                            
                                /> 
                                    <TextField
                                    required
                                    id="purchasing"
                                    value={data?data.purchasing:''}
                                    label="Purchasing"
                                    fullWidth
                                    margin='normal'
                                    onChange={(event) => setData({...data, purchasing: event.target.value})}
                            
                                /> 
                                    <TextField
                                    required
                                    id="logistics"
                                    label="Logistics"
                                    value={data?data.logistics:''}
                                    fullWidth
                                    margin='normal'
                                    onChange={(event) => setData({...data, logistics: event.target.value})}
                            
                                /> 
                                    <TextField
                                    required
                                    id="startDate"
                                    value={data?data.start_date:''}
                                    fullWidth
                                    margin='normal'
                                    onChange={(event) => setData({...data, start_date: event.target.value})}
                                    error={fieldErrors.start_date?true:false}
                                    helperText={fieldErrors.start_date?fieldErrors.start_date:'Date that management contract commenced'}
                                    type="date"
                            
                                /> 
                                <TextField
                                    required
                                    id="endDate"
                                    placeholder="End date"
                                    value={data?data.end_date:''}
                                    fullWidth
                                    margin='normal'
                                    error={fieldErrors.end_date?true:false}
                                    onChange={(event) => setData({...data, end_date: event.target.value})}
                                    helperText={fieldErrors.end_date?fieldErrors.end_date:'Leave blank until vessel is no longer managed'}
                                    type="date"
                            
                                /> 
                                <Autocomplete
                                    disablePortal
                                    id="operating-region"
                                    options={locations?locations:[]}
                                    sx={{ width: '100%', marginTop:'10px'}} 
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {setData({...data, operating_region: newValue.label})}}
                                    value={data?{label: data.operating_region, id: data.operating_region}:null}
                                    renderInput={params =>   
                                        <TextField
                                            {...params}
                                            label={"Selecting a opeating region"}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                                style={{width:'100%'}}
                            /> 
                            <Divider style={{marginTop:'20px'}}/>
                            <TextField
                                            required
                                            id="imo"
                                            inputProps={{readOnly:true}}
                                            label="imo"
                                            value={data? data.imo:''}
                                            fullWidth
                                            margin='normal'
                                    
                                        /> 
                                        <TextField
                                            required
                                            id="shipName"
                                            inputProps={{readOnly:true}}
                                            label="Ship Name"
                                            value={data? data.ship_name:''}
                                            fullWidth
                                            margin='normal'
                                    
                                        /> 
                                        <TextField
                                            required
                                            id="ship_type"
                                            inputProps={{readOnly:true}}
                                            label="Ship type"
                                            value={data?data.ship_type:''}
                                            fullWidth
                                            margin='normal'
                                    
                                        /> 
                                        <TextField
                                            required
                                            id="dwt"
                                            inputProps={{readOnly:true}}
                                            label="dwt"
                                            value={data?data.dwt:''}
                                            fullWidth
                                            margin='normal'
                                    
                                        /> 
                                <Button variant="contained" color="primary" style={{marginTop:'10px', marginLeft:'5px'}} onClick={handelUpdate}>Update</Button>
                        </Grid>
                    </Grid>
                </>
                
                }
            </>
            )}
        </Grid>
    )
}