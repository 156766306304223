import React, {useEffect } from 'react';
import { FormGroup, FormControl,  Button } from '@mui/material';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import CustomAlert from './Alert';


export default function ExchangeRates({token}) {
    const [exchangeRates , setExchangeRates] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    useEffect(() => {
       fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/exchangerates/`, {
            method: 'GET',
            headers: {
                'authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setExchangeRates(data);
            setLoading(false);
        })
        .catch(err => console.log(err))
    }
    , [token]);

    const handelChange = (event, index,  rate=true) => {
        const tempRates = [...exchangeRates];
        if(rate){
            tempRates[index].rate = event.target.value;
        }
        else{
            tempRates[index].symbol = event.target.value;
        }
        setExchangeRates(tempRates);
    }
    const handelUpdate = (event, index) => {
        const rate = exchangeRates[index];
        setSuccess(false);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/exchangerate/${exchangeRates[index].symbol}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(rate)
        })
        .then(response => response.json())
        .then(data => setSuccess(true))
        .catch(err => {
            console.log(err);
            setSuccess(false);
        })
    }

    return (
        <>
            {success?<CustomAlert success={success} message={"Exchange rate updated successfully"} disappear={true}/>:null}
            {loading?<CircularProgress/>:exchangeRates.length > 0 && exchangeRates.map((item, index) => {
                return (
                    <FormGroup>
                        <FormControl sx={{marginTop:'50px'}}>
                            <InputLabel htmlFor="symbol">Currency</InputLabel>
                            <Input id="symbol" aria-describedby="symbol" step="1" value={item.symbol} sx={{marginLeft:'15px'}} onChange={(event) => {handelChange(event, index)}}/>
                        </FormControl>
                        <FormControl sx={{marginTop:'50px'}}>
                            <InputLabel htmlFor="rate">Rate</InputLabel>
                            <Input type="number" id="rate" step="1" aria-describedby="rate" value={item.rate} sx={{marginLeft:'15px'}} onChange={(event) => {handelChange(event, index)}}/>
                        </FormControl>
                        <Button variant="contained" sx={{marginLeft:'15px', marginTop:'20px', width:'10%'}} onClick={(event) => {handelUpdate(event, index)}}>Update</Button>
                    </FormGroup>
                )
            })}
        </>
    );
}