import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from "react";
import {Typography, Grid, Box, Divider} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CustomAlert from './Alert';
import WarningIcon from '@mui/icons-material/Warning';


export default function Schedule({token}){
  const {imo} = useParams();
  const [schedule, setSchedule] = useState([]);
  const [addDestination, setAddDestination] = useState(false);
  const[ports, setPorts] = useState([]);
  const [error , setError] = useState({
    port_error: false,
    ata_eta_error: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    port_error: '',
    ata_eta_error: '',
  });
  const [newSchedule, setNewSchedule] = useState({
    port_id: '',
    ata_eta: '',
  });
  const [updateError, setUpdateError] = useState(false);
  const [updateErrorMessage, setUpdateErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const[ success, setSuccess] = useState(false);
  const[updateFormError, setUpdateFormError] = useState(false);
  const[updateFormErrorMessage, setUpdateFormErrorMessage] = useState('');

  const updateSchedule = (n, label, value) => {
    const oldSchedule = schedule;
    const updatedSchedule = oldSchedule.map(item => {
        if(item.n === n){
            const newItem = {
                ...item,
                [label]: value
            }
            return newItem;
        }else{
            return item;
        }
    })
    setSchedule(updatedSchedule);
  }
  const scheduleForm = (n, label, value, disabled) => {
    const labelMapping = {
        'actual time of arrivial/ estimated time of arrival': 'ata_eta',
    }
    return (
            <TextField 
                id={`${n}-${label}`}
                label={label}
                variant="outlined" 
                defaultValue={value} 
                disabled={label !== 'actual time of arrivial/ estimated time of arrival'?true:disabled}
                sx={{marginTop: '10px', minWidth: '100%'}}
                value={schedule.filter(item => item.n === n)[0][label]}
                error={!disabled?updateError: false}
                helperText={!disabled?updateErrorMessage:''}
                onChange={e => {
                    if(e.target.value === ''){
                        setUpdateError(true);
                        setUpdateErrorMessage(`${label} is required`);
                        return;
                    }
                    updateSchedule(n, labelMapping[label], e.target.value)
                }}
            />
    )
  }

  const createNewSchedule = () => {
    setSuccessMessage('');
    setSuccess(false);
    setError({
        port_error: false,
        ata_eta_error: false,
    });
    if(newSchedule.port_id === ''){
        setError({
            port_error: true,
        });
        setErrorMessage({
            port_error: 'Port is required',
        });
        return;
    }
    fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/schedule/${imo}/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(newSchedule)
        }
    )
    .then(response => {
        if(response.status === 400){
            return response.text().then((text) => {
                setError({
                    ata_eta_error: true,
                });
                setErrorMessage({
                    ata_eta_error: text,
                });  // Set the error message returned by the server
                throw new Error(text);  // Throw error to catch block
            });
        }
        return response.json()
    })
    .then(data => {
        // remove old schedule
        setSchedule([]);
        setSchedule(data);
        setSuccess(true);
        setSuccessMessage('New schedule added successfully');
        setAddDestination(false);
    })
    .catch(error => {
        setAddDestination(true);
        console.error(error);
    });
  }
  const editSchedule = (n) => {
    const selectedSchedule = schedule.filter(item => item.n === n)[0];
    fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/schedule/${imo}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(selectedSchedule)
            }
        )
        .then(response => {
            if(response.status === 400){
                console.log(response);
                return response.text().then((text) => {
                    setUpdateFormError(true);
                    setUpdateFormErrorMessage(text);  // Set the error message returned by the server
                    throw new Error(text);  // Throw error to catch block
                });
            }
            return response.json()
        })
        .then(data => {
            const formattedSchedule = data.map((item, index) => {
                return {
                    n: index,
                    port_id: item.port_id,
                    ata_eta: item.ata_eta,
                    src: item.src,
                    port_name: item.port_name,
                    edit: item.src==='user'? false: true,
                    plausible: item.plausible
    
                }
            })
            setSchedule(formattedSchedule);
        })
        .catch(error => {
            console.error(error);
      });
    }
  useEffect(() => {

    
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/schedule/${imo}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        const formattedSchedule = data.map((item, index) => {
            return {
                n: index,
                port_id: item.port_id,
                ata_eta: item.ata_eta,
                src: item.src,
                port_name: item.port_name,
                edit: item.src==='user'? false: true,
                plausible: item.plausible

            }
        })
        setSchedule(formattedSchedule);
    })
    .catch(error => {
        console.error(error);
    });
  }, [imo, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [portsResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_BACKEND_URL}/ports/list/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
          }),
        ]);

        if (!portsResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const [portsData] = await Promise.all([
          portsResponse.json()
        ]);
        console.log(portsData);
        if(portsData){
            const portsLookup = portsData.map((port) => {
                return {
                    id: port.port_id,
                    label: `${port.name}-${port.UN_code}`
                }
            });
            setPorts(portsLookup);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        console.log('done')
      }
    };

    fetchData();
  }, [token]);


  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => `${option.label}-${option.UN_code}`,
  });
  return (
   <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item xs={12} >
            {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color='primary' sx={{marginTop:'2px', marginLeft:'2px'}} onClick={e => {setAddDestination(true)}}>Add new destination</Button>
            </Grid>
            <Grid item xs={12}>
            {addDestination? (
                <>
                    <Grid item xs={12} sx={{marginTop:'10px'}}>
                        <Autocomplete
                            disablePortal
                            id="ports"
                            options={ports}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => option.label}
                            onChange={(option, value) => {newSchedule.port_id = value.id}}
                            renderInput={params =>   
                                <TextField
                                    {...params}
                                    label="Select a port"
                                    variant="outlined"
                                    fullWidth
                                    error={error.port_error}
                                    helperText={errorMessage.port_error}
                                />
                            }
                            filterOptions={filterOptions}
                            style={{width:'100%'}}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop:'10px'}}>
                    <TextField 
                            id='ata_eta'
                            variant="outlined"
                            fullWidth
                            value={newSchedule.ata_eta}
                            onChange={e => {setNewSchedule({...newSchedule, ata_eta: e.target.value})}}
                            error={error.ata_eta_error}
                            helperText={errorMessage.ata_eta_error? errorMessage.ata_eta_error: 'Enter the actual or estimated date of arrival'}
                            type="date"
                    />
                    </Grid>
                    <Button variant="contained" color='primary' sx={{marginTop: '10px'}} onClick={e => {createNewSchedule()}}>Save</Button>
                    <Divider sx={{marginTop: '10px'}}/>
                </>

            ): null}
            </Grid>
            <Grid item xs={12} >
            {updateFormError ? <CustomAlert error message={updateFormErrorMessage} disappear={true}/>:null}
            </Grid>
            {schedule.map((item, index) => {
                return (
                    <Grid item xs={12} key={`${item.n}-grid`}>
                        <Box sx={{ width: '100%' }}>
    
                            {item.plausible?null:(
                                <Grid item xs={12}>
                                    <IconButton color="warning">
                                        <WarningIcon />
                                        <Typography variant="body2" component="h6" color="warning">Current ETA implausable</Typography>
                                    </IconButton>
                                </Grid>
                            )}
                            <Typography variant="body2" sx={{marginTop: '10px'}}>{`Destination ${item.n+1}`}</Typography>
                            <Autocomplete
                                    id="edit-ports"
                                    options={ports}
                                    disabled={item.src==='user'?false:true}
                                    sx={{ width: 300, marginTop: '10px'}}
                                    getOptionLabel={(option) => option.label}
                                    value={ports.filter(port => port.id === item.port_id)[0]}
                                    onChange={(event, value) => {
                                        if (value) {
                                            // Ensure `value` exists before accessing its `id`
                                            updateSchedule(item.n, 'port_id', value.id);
                                        }
                                    }}
                                    renderInput={params =>  {
                                        return (
                                            <TextField
                                                {...params}
                                                label= {item.port_name}
                                                variant="outlined"
                                                fullWidth
                                                disabled={item.edit}
                                            />
                                        )
                                    }
                
                                        
                                }
                                filterOptions={filterOptions}
                                style={{width:'100%'}}
                            />
                            
                            <Grid item xs={12}>
                                {scheduleForm(item.n, "actual time of arrivial/ estimated time of arrival", item.ata_eta, item.edit)}
                            </Grid>
                            <Grid item xs={12}>
                                {scheduleForm(item.n, "Source", item.src, item.edit)}
                            </Grid>
                            {item.src==='user'?<Button variant="contained" color='primary' sx={{marginTop: '10px'}} onClick={e => {editSchedule(item.n)}}>Save</Button>:null}
                        </Box>
                        <Divider sx={{marginTop: '10px'}}/>
                    </Grid>
                )
            })}
    </Grid>
  )
}