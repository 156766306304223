import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {Typography, Box,  MenuItem, Menu } from '@mui/material';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


export default function Nav({loggedIn, darkMode, setDarkMode}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [consolidationAnchorEl, setConsolidationAnchorEl] = React.useState(null);
    const [UserSettingAnchorEl, setUserSettingAnchorEl] = React.useState(null);
    const [renderHubDropdownAnchorEl, setHubDropdownAnchorEl] = React.useState(null);
    const [isUserSettingMenuOpen, setIsUserSettingMenuOpen] = React.useState(false);
    const[loggedInState, setLoggedIn] = React.useState(false);
    const isConsolidationMenuOpen = Boolean(consolidationAnchorEl);
    const isHubDropDownMenuOpen = Boolean(renderHubDropdownAnchorEl); 
  
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleConsolidationMenuClose = () => {
        setConsolidationAnchorEl(null);
        handleMenuClose();
    };
    const handleUserSettingsMenuClose = () => {
      setUserSettingAnchorEl(null);
      setIsUserSettingMenuOpen(false);
    }

    const handleMenuClose = () => {
      setAnchorEl(null);
      setConsolidationAnchorEl(null);
    };
    const handleUserSettingMenuOpen = (event) => {
      setUserSettingAnchorEl(event.currentTarget);
      setIsUserSettingMenuOpen(true);
    }

    const handleHubDropDownMenuClose = () => {
      setHubDropdownAnchorEl(null);
    }
    
    const renderHubDropdown = (
      <Menu
        anchorEl={renderHubDropdownAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={"consolidation-menu"}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isHubDropDownMenuOpen}
        onClose={handleHubDropDownMenuClose}
      >
          <Link to="/opportunites" style={darkMode?{ textDecoration: 'none', backgroundColor:'black', color:'white'}: { textDecoration: 'none', backgroundColor:'white', color:'black'}}>
            <MenuItem onClick={handleHubDropDownMenuClose}>Opportunites</MenuItem>
          </Link>
          <Link to="/location/status" style={darkMode?{ textDecoration: 'none', backgroundColor:'black', color:'white'}: { textDecoration: 'none', backgroundColor:'white', color:'black'}}>
            <Tooltip title="Actvity at the port" arrow placement="bottom">
              <MenuItem onClick={handleHubDropDownMenuClose}>Port Status</MenuItem>
            </Tooltip>
          </Link>
      </Menu>

    );
    const renderConsolidationMenu = (
      <Menu
        anchorEl={consolidationAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={"consolidation-menu"}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isConsolidationMenuOpen}
        onClose={handleUserSettingsMenuClose}
      >
          <Link to="/recommended/consolidations" style={darkMode?{ textDecoration: 'none', backgroundColor:'black', color:'white'}: { textDecoration: 'none', backgroundColor:'white', color:'black'}}>
            <MenuItem onClick={handleConsolidationMenuClose}>Recommended Consolidations</MenuItem>
          </Link>
          <Link to="/inprocess/consols" style={darkMode?{ textDecoration: 'none', backgroundColor:'black', color:'white'}: { textDecoration: 'none', backgroundColor:'white', color:'black'}}>
            <Tooltip title="A grouping of individual shipments into one large consolidated shipment" arrow placement="bottom">
              <MenuItem onClick={handleConsolidationMenuClose}>In Process Consols</MenuItem>
            </Tooltip>
          </Link>
      </Menu>
    );

    const renderUserSettingMenu = (
      <Menu
        anchorEl={UserSettingAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={"user-setting-menu"}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isUserSettingMenuOpen}
        onClose={handleConsolidationMenuClose}
      >
          <MenuItem>
            <FormControlLabel onClick={handleUserSettingsMenuClose} control={<Switch  checked={darkMode} onChange={()=>setDarkMode(!darkMode)}/> } label="Dark mode" />
          </MenuItem><Link style={darkMode?{ textDecoration: 'none', backgroundColor:'black', color:'white'}: { textDecoration: 'none', backgroundColor:'white', color:'black'}}>
            <MenuItem onClick={handleUserSettingsMenuClose} component={Link} to="/settings">Settings</MenuItem>
          </Link>
          <Link style={darkMode?{ textDecoration: 'none', backgroundColor:'black', color:'white'}: { textDecoration: 'none', backgroundColor:'white', color:'black'}}>
            <MenuItem onClick={handleUserSettingsMenuClose} component={Link} to="/logout">Logout</MenuItem>
          </Link>
          
      </Menu>
    );

    React.useEffect(() => {
        setLoggedIn(loggedIn);
    },[loggedIn]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
            <Box sx={{ flexGrow: 1, display:{xs:'block', sm:'block', md:'none', lg:'none'}}}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                      {!loggedInState?<MenuItem component={Link} to="/" onClick={handleMenuClose}> Home</MenuItem>:null}
                      {loggedInState?<MenuItem onClick={(event) => {setHubDropdownAnchorEl(event.currentTarget)}}>Hubs</MenuItem>:null}
                      {renderHubDropdown}

                      {loggedInState?<MenuItem onClick={(event) => {setConsolidationAnchorEl(event.currentTarget)}}>Consolidations</MenuItem>:null}
                      {renderConsolidationMenu}
                      {loggedInState?<MenuItem component={Link} to="/parts" onClick={handleMenuClose}>Stock</MenuItem>:null}
                      {loggedInState?<MenuItem component={Link} to="/fleet" onClick={handleMenuClose}>Fleet</MenuItem>:null}
                      {!loggedInState?<MenuItem component={Link} to="/login">Login</MenuItem>:null}
                      {loggedInState?  
                        <Box>
                          <Tooltip title="Open settings">
                            <IconButton onClick={handleUserSettingMenuOpen} sx={{ p: 0, marginLeft:{sx:'10px', xs:'10px'} }}>
                              <SettingsIcon sx={{color:darkMode?'white':'black'}}/>
                            </IconButton>
                          </Tooltip>
                          {renderUserSettingMenu}
                        </Box>
                      :null}
                    </Menu>
            </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 2 }}>
            ConsCom
          </Typography>
          <Box sx={{ display: { xs: 'none', sm:'none', md: 'block' } }}>
                {loggedInState?
                    <>
                      <Button color="inherit" onClick={(event)=> setHubDropdownAnchorEl(event.currentTarget)}>Hubs</Button>
                      {renderHubDropdown}
                      <Button color="inherit" onClick={(event)=> setConsolidationAnchorEl(event.currentTarget)}>Consolidations</Button>
                      {renderConsolidationMenu}
                      <Button component={Link} to="/parts" color="inherit">Stock</Button>
                      <Button component={Link} to="/fleet" color="inherit">Fleet</Button>
                      <Button component={Link} to="/network" color="inherit">Network</Button>
                      <Button component={Link}  to="/network/design" color="inherit">Network design</Button>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleUserSettingMenuOpen} sx={{ p: 0, marginLeft:{sx:'10px', xs:'10px'} }}>
                        <SettingsIcon sx={{color:'white'}}/>
                        </IconButton>
                      </Tooltip>
                      {renderUserSettingMenu}
                    </>
                    :
                    <>
                    <Button component={Link} to="/" color="inherit">Home</Button>
                    <Button  component={Link} to="/login" color="inherit">Login</Button>
                    </>

                }
         </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}