import { useNavigate } from 'react-router-dom';
import  { useEffect } from 'react';

export default function Logout ({setLoggedIn, setDarkMode}){
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem('logistics_manager_token');
        localStorage.removeItem('logistics_manager_has_fleet');
        setLoggedIn(false);
        navigate('/login');
        setDarkMode(false);
    },[setLoggedIn, navigate, setDarkMode])
    return null;
}