import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {Typography, Grid, Paper, Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';

export default function Fleet({token}){
    const [fleet, setFleet] = useState([]);
    const [oldFleet, setOldFleet] = useState([]);
    const [fleetLookup, setFleetLookup] = useState([]);
    const[loading, setLoading] = useState(true);

    const downloadFleet = () => {
        console.log('Download fleet');
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/download/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Token ${token}`
            }
        })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "data.csv";
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('Failed to fetch data:', error);
        })

    }


    const filterFleet = (event, newVal) => {
        if(!newVal){
            setFleet(oldFleet);
            return
        }
        const filteredFleet = fleet.filter(vessel => vessel.ship_name? vessel.ship_name.includes(newVal.label): vessel.ship_name);
        if(!filteredFleet.length){
            setFleet([oldFleet]);
        }else{
            setFleet(filteredFleet);
        }
    }
    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const fleetLookupData = data.map((ship) => {
                return {
                    id: ship.imo,
                    label: `${ship.ship_name}`
                }
            });
           setFleetLookup(fleetLookupData);
           setFleet(data);
           setOldFleet(data);
           setLoading(false);
        })
        .catch((error) => {
            console.error('Failed to fetch data:', error);
            setLoading(false);
        })
    }, [token])
    return (
        <Grid container spacing={2} sx={{marginTop:'10px'}}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
                <SearchIcon/>
                <Autocomplete
                    disablePortal
                    id="search-by-id"
                    options={fleetLookup}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option.label}
                    onChange={filterFleet}
                    renderInput={params =>   
                        <TextField 
                            {...params}
                            label="Search by ship name"
                            variant="outlined"
                            fullWidth
                        />
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                    style={{width:'100%'}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button variant="outlined" endIcon={<DownloadIcon />} sx={{marginTop:'12%'}} onClick={downloadFleet}>
                    Download fleet as csv
                </Button>
            </Grid>
            {loading ? 
             <Grid container spacing={2} style={{marginTop:'300px', textAlign:'center'}}>
                <Grid item xs={12} m={12}>
                    <CircularProgress size={100}/>
                </Grid>
                </Grid>
            :
                fleet.length?fleet.map((vessel, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                            <Paper sx={{padding: '10px'}}>
                                <DirectionsBoatIcon fontSize="small" />
                                <Typography variant="h3">Name: {vessel.ship_name}</Typography>
                                <Grid container>
                                     <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography variant="body1">IMO: {vessel.imo}</Typography>
                                        <Typography variant="body1">Ship type: {vessel.ship_type}</Typography>
                                        <Typography variant="body1">DWT: {vessel.dwt}</Typography>
                                        <Link to={`/schedule/${vessel.imo}`}>
                                            <Button variant="contained" size="small" color="primary">Destinations</Button>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography variant="body1">Logistics: {vessel.logistics}</Typography>
                                        <Typography variant="body1">Customer name: {vessel.customer_name}</Typography>
                                        <Typography variant="body1">Supervisor: {vessel.supervisor}</Typography>
                                        <Link to={`/ship/detail/${vessel.imo}`}>
                                            <Button variant="contained" size="small" color="primary">Details</Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                })
            
            
           :null }
        </Grid>
    )
}