import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {Paper, Typography,Box} from '@mui/material';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ConstructionIcon from '@mui/icons-material/Construction';
import FilterListIcon from '@mui/icons-material/FilterList';
import UndoIcon from '@mui/icons-material/Undo'
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';





export default function Opportunites({token, darkMode}) {
    const [loading, setLoading] = useState(true);
    const [spares, setSpares] = useState([]);
    const [oldSpares, setOldSpares] = useState([]);
    const [locations, setLocations] = useState([]);
    const [error, setError] = useState(null);
    const [selectedHub , setSelectedHub] = useState("Select a location");
    const [clickedImo, setClickedImo] = useState(0);
    const [parts , setParts] = useState([]);
    const [oldParts , setOldParts] = useState([]);
    const [opportunites, setOpportunites] = useState([]);
    const [oldOpportunites, setOldOpportunites] = useState([]);

    function assignPartsandOpportunites(index, imo){
        setClickedImo(index);
        const data = spares.filter(spare => spare.imo === imo);
        if(data.length){
            setParts(data[0].parts);
            setOldParts(data[0].parts);
            setOpportunites(data[0].opportunities);
            setOldOpportunites(data[0].opportunities);
        }else{
            setParts([]);
            setOldParts([]);
            setOpportunites([]);
            setOldOpportunites([]);
        }
    }

    const filterParts = (id) => {
        return () => {
            if(!id){
                setParts(oldParts);
            }else{
                const filterParts = parts.filter((item) => item.card_colour === id);
                setParts(filterParts);
            }
            // Perform your filtering or other logic here
        };
    }


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/locations/?d=y`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_name}
            })
            setLocations(newData);
            setSelectedHub(newData[0]);
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/location/?location_name=${data[0].location_name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Token ${token}`
                }
            })
            .then(response2 => {
                if(response2.ok){
                    return response2.json();
                }else{
                    throw new Error(response2.statusText);
                }
            })
            .then(data2 => {
                setError(null);
                setSpares(data2.spares);
                setOldSpares(data2.spares);
                setParts(data2.spares[0].parts);
                setOpportunites(data2.spares[0].opportunities);
                setLoading(false);
            })
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }, [token]);
    const makeAPiCall = (event, newVal) => {
        setLoading(true);
        const filteredLocation = locations.filter(location => location.label === newVal.label);
        setSelectedHub(filteredLocation[0]);
        setClickedImo(0);
        if(!newVal){
            setError(null);
            setSpares([]);
            return;
        }
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/location/?location_name=${newVal.label}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(response.ok){
                return response.json();
            }else{
                throw new Error(response.statusText);
            }
        })
        .then(data => {
            setError(null);
            setSpares(data.spares);
            setOldSpares(data.spares);
            setParts(data.spares[0].parts);
            setOpportunites(data.spares[0].opportunities);
            setOldOpportunites(data.spares[0].opportunities);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setError(error.message);
            setSpares([])
            setOldSpares([])
        });

        assignPartsandOpportunites(0, 0);
    }
    return (
        loading ? (
            <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
              <Grid item xs={12} m={6}>
                <CircularProgress size={100}/>
              </Grid>
            </Grid>
        ) :(
                <Grid container spacing={2} style={{marginTop:'5px'}}>
                    {error && <Typography variant="body1" component="body1" sx={{textAlign:'center', marginLeft:'20px'}}>{error}</Typography>}
                    <Grid item xs={12} sm={12}>
                        {/* <InputLabel htmlFor="standard-adornment-amount">Search by spare id</InputLabel>
                        <Input
                            id="standard-adornment-amount"
                            type="text"
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                            fullWidth
                            onChange={filterFleet}
                            color="secondary"
                        /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} style={{marginTop:'10px'}}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={locations}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => option.label}
                            onChange={makeAPiCall}
                            value={selectedHub}
                            renderInput={params =>   
                                <TextField
                                    {...params}
                                    label="Select a location"
                                    variant="outlined"
                                    fullWidth
                                />
                            }

                            isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                            style={{width:'100%'}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop:'5px'}}>
                            <Stack direction={{ xs: "column", sm: "column", lg:"row"}} spacing={1}>
                                    <Chip 
                                        onClick={filterParts("#B5E6A2")} label="Delivery opportunites at hub" size="medium" sx={{backgroundColor:"#B5E6A2",color:darkMode?'grey':''}} icon={<FilterListIcon color="secondary"/>} variant="outlined"
                                    />
                                    <Chip onClick={filterParts("#FFCC00")} label="Delivery opportunites at other ports" size="medium" sx={{backgroundColor:"#FFCC00",color:darkMode?'grey':''}}  icon={<FilterListIcon color="secondary"/>} variant="outlined"
                                    />
                                    <Chip  onClick={filterParts("#CCECFF")} label="No immediate delivery opportunites" size="medium" sx={{backgroundColor:"#CCECFF", color:darkMode?'grey':''}} icon={<FilterListIcon color="secondary"/>} variant="outlined"
                                    />
                                    <Chip  onClick={filterParts(undefined)} size="medium"  icon={<UndoIcon/>} variant="outlined"
                                    />
                            </Stack>
                    </Grid>
                    <Grid container spacing={2} style={{marginTop:'5px', marginBottom:'50px'}}>
                            <Grid item xs={12} sm={12} md={4} lg={4} ipad={12}>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', marginLeft:'20px', textDecoration:'underline'}}>Vessels</Typography>
                                <List sx={{ width: '100%', display:'flex', marginLeft:'20px', marginTop:'12px', flexDirection:{
                                    xs:'column',
                                    sm:'column',
                                    custom:'column',
                                    surface:'column',
                                    md:'row',
                                    lg:'column',
                                }}} >
                                    {spares.length > 0? spares.map((item, index) => {
                                    return ( <><ListItem key={`${item.imo}-item`} sx={{margin: '20px 0', boxShadow: `0 0 15px ${item.card_colour}`, backgroundColor:clickedImo===index? darkMode?'#473c3c':'#e3e3e3': '', marginLeft:'.5%', cursor:'pointer'}} onClick={()=>{assignPartsandOpportunites(index,item.imo)}}>
                                                    <ListItemAvatar key={`${item.imo}-avatar`}>
                                                        <Avatar>
                                                            <DirectionsBoatIcon/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={`IMO: ${item.imo}( ${item.ship_name} )`} secondary={`DWT: ${item.dwt}`} />
                                                    <Box key={`${item.imo}-box`}>
                                                        <Link to={`/ship/detail/${item.imo}`}>
                                                            <Button variant="contained" color="primary" size="small">
                                                                Details....
                                                            </Button>
                                                        </Link>
                                                    </Box>
                                            </ListItem>
                                            </>
                                    )
                                    }):null}
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} ipad={12}>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', marginLeft:'20px',textDecoration:'underline'}}>Parts</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} ipad={12}>
                                        {parts && parts.length > 0? parts.map((item, index) => {
                                            return (
                                                <Paper
                                                    key={index}
                                                    elevation={3}
                                                    style={{ padding: '20px', marginTop:'40px', marginLeft:'30px', boxShadow: `0 0 15px ${item.card_colour}`}}
                                                >
                                                    <ConstructionIcon fontSize="small" color="primary" />
                                                    <Typography variant="body1" fontWeight={'bold'}>
                                                            Spare id: {item.spare_id}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                            Part Description: {item.description}
                                                    </Typography>
                                                    <Typography variant="body2" >
                                                            Status: {item.status}
                                                    </Typography>
                                                    <Link to={`/part/${item.spare_id}`}>
                                                        <Button variant="contained" color="primary" size="small">
                                                            Click for more info....
                                                        </Button>
                                                    </Link>
                                                </Paper>
                                            )
                                        }):null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} ipad={12}>
                                <Typography variant="body1" component="p" sx={{textAlign:'center', marginLeft:'20px',textDecoration:'underline'}}>Opportunities</Typography>
                                {opportunites && opportunites.length > 0? opportunites.map((item, index) => {
                                    return (
                                        <>  
                                        <Paper
                                            key={index}
                                            elevation={3}
                                            style={{ padding: '20px', marginTop:'40px', marginLeft:'30px', boxShadow: `0 0 15px ${item.card_colour}` }}
                                        >
                                            <DirectionsBoatIcon fontSize="small" color="primary" />
                                            <Typography variant="body2" component="h2" >
                                                Destination: {item.port_name}
                                            </Typography>
                                            <Typography variant="body2" component="h2" >
                                                ATA/ETA: {item.ata_eta}
                                            </Typography>
                                            <Typography variant="body2" component="h2" >
                                                Delivery Description: {item.delivery_description}
                                            </Typography>
                                            <Typography variant="body2" component="h2" >
                                                Delivery Cost: {item.delivery_cost}
                                            </Typography>
                                            <Typography variant="body2" component="h2" >
                                                Final Mile Cost: {item.final_mile_cost}
                                            </Typography>
                                        </Paper>
                                        </>                                  )
                                }):<Typography variant="body1" component="p" sx={{textAlign:'center', marginLeft:'20px'}}>None</Typography>}
                            </Grid>
                    </Grid>
            </Grid>
        )
            
    )
}
