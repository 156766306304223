
import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Nav from './components/Nav';
import Home from './components/Home';
import Opportunites from './components/Opportunites';
import Part from './components/Part';
import Parts from './components/Parts';
import Network from './components/Network';
import AddPart from './components/AddPart';
import Schedule from './components/Schedule';
import Fleet from './components/Fleet';
import Login from './components/Login';
import Logout from './components/Logout';
import Consolidations from './components/Consolidations';
import NetworkDesign from './components/NetworkDesign'; 
import UploadFleet from './components/UploadFleet';
import MoreInfo from './components/MoreInfo';
import Details from './components/Detail';
import InProcessConsols from './components/InProcessConsols';
import NotFound from './components/NotFound';
import ConsoleDetail from './components/ConsolDetail';
import LocationStatus from './components/LocationStatus';
import UserSettings from './components/UserSettings';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";


const lightTheme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#284db7',
    },
    secondary: {
      main: '#612785',
    },
    background: {
      paper: '#FFFFFF',
      card: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    },
    

    
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      custom: 1024,
      surface: 912,
      nest: 1280,
      ipad:768
    },
  },
});

const darkTheme = createTheme({
  // change the body background to black
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    mode: 'dark',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      custom: 1024,
      surface: 912,
      nest: 1280,
      ipad:768
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'input[type="date"]::-webkit-calendar-picker-indicator': {
          filter: 'invert(1)', // Invert color for dark mode
        },
      },
    },
  },

});

function App() {
  const [hasFleet, setHasFleet] = React.useState(
    JSON.parse(localStorage.getItem('logistics_manager_has_fleet') || 'false')
  );
  const [loggedIn, setLoggedIn] = React.useState(
    !!localStorage.getItem('logistics_manager_token')
  );
  const [token, setToken] = React.useState(
    localStorage.getItem('logistics_manager_token') || undefined
  );
  const [darkMode, setDarkMode] = React.useState(false);

  useEffect(() => {
    // Update logged-in state if token exists
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [token]);

  return (
    <ThemeProvider theme={darkMode?darkTheme:lightTheme}>
      <CssBaseline />
        <>
              <BrowserRouter>
                <Nav loggedIn={loggedIn} darkMode={darkMode} setDarkMode={setDarkMode}/>
                <Container  sx={{
                  maxWidth: {
                    xs: '100%',   // Full width on extra small screens
                    sm: '100%',   // Full width on small screens
                    md: '100%',    // 90% width on medium screens
                    lg: '100%',    // 80% width on large screens
                    xl: '100%',    // 70% width on extra large screens
                  },
                }}>
                  <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/opportunites" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Opportunites token={token} darkMode={darkMode}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/network" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Network token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/network/design" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<NetworkDesign token={token}/>:<Navigate to="/upload/fleet"/>}/>
                    <Route path="/fleet/" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Fleet token={token}/>:<Navigate to="/upload/fleet/"/>} />
                    <Route path="/recommended/consolidations" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Consolidations token={token} darkMode={darkMode}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/part/:id" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Part token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/parts/" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Parts token={token} />:<Navigate to="/upload/fleet"/>} />
                    <Route path="/part/add" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<AddPart token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/schedule/:imo" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Schedule token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/ship/detail/:imo" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<Details token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/login" element={<Login setToken={setToken} setLoggedIn={setLoggedIn} setHasFleet={setHasFleet}/>} />
                    <Route path="/inprocess/consols" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<InProcessConsols token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/consol/detail/:id" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<ConsoleDetail token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/location/status/" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<LocationStatus token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/settings" element={!loggedIn?<Navigate to="/login"/>:hasFleet?<UserSettings token={token}/>:<Navigate to="/upload/fleet"/>} />
                    <Route path="/upload/fleet" element={<UploadFleet token={token} setHasFleet={setHasFleet}/>} />
                    <Route path="/more/info" element={<MoreInfo />} />
                    <Route path="/logout" element={<Logout setLoggedIn={setLoggedIn} setDarkMode={setDarkMode}/>} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Container>
              </BrowserRouter>
        </>
    </ThemeProvider>
  );
}

export default App;