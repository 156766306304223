import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {Grid} from '@mui/material';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import ColumnHeaders from "./ColumnHeaders";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});



export default function UploadFleet({token, setHasFleet}){ 
  const navigate = useNavigate();
  const [columns , setColumns] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);


  const handel_file_submit = () => {
    if(file){
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/import/`, {
          method: 'POST',
          headers: {
            'authorization': `Token ${token}`
          },
          body: formData
      })
      .then(response => {
          if(response.status === 400){
              alert('Invalid file format');
          }
          setLoading(false);
          return response.json();
      })
      .then(data => {
        setHasFleet(true);
          navigate('/fleet');
      })
      .catch((error) => {
          setLoading(false);
          console.error('Failed to post fleet data:', error);
      })
    }
  }
  
  const handel_file_select = (e) => {
    if(e.target.files){
      setFile(e.target.files[0]);
    }
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/cols/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'applicationn',
          'authorization': `Token ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        setColumns(data);
    })
    .catch((error) => {
        console.error('Failed to fetch data:', error);
    })
  }, [token]);
    return (
        <Grid container spacing={2} sx={{marginTop:'1%', marginBottom:'2%'}}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h2" component="h2" sx={{textAlign:'center', marginLeft:'5px'}}>
                      Upload Your Fleet
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" component="h2" sx={{marginLeft:'5px'}}>
                    File Format
              </Typography>
              <Typography variant="body2" sx={{marginLeft:'5px'}}>
                The columns below are required for the file to be uploaded successfully.
              </Typography>
              <ColumnHeaders token={token} update={false} callback={setColumns}/>
             
              <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Choose file
                  <VisuallyHiddenInput
                    type="file"
                    accept=".csv"
                    name="file"
                    id="file"
                    // post the form on change
                    onChange={handel_file_select}
                />
              </Button>
              {file?<>
                <List sx={{marginTop:'20px'}}>
                  <ListItem>
                    <ListItemText primary={file.name} secondary={Number(file.size) < 1000000? `${file.size}kb`: `${file.size}mb` } />
                  </ListItem>
                </List>
                <Button onClick={handel_file_submit} variant="contained" sx={{marginTop:'20px'}}>Upload</Button>
                {loading?<CircularProgress sx={{marginTop:'20px'}}/>:null}
              </>:null}
            </Grid>
        </Grid>
    );
    
}