import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import {Divider, Paper, Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import CustomAlert from './Alert';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import DownloadIcon from '@mui/icons-material/Download';




export default function Consolidations({token, darkMode}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');
    const [hubs , setHubs] = useState([]);
    const checkedItemsRef = useRef({});
    const [originName, setOriginName] = useState('');
    const [masterConsolidationKey, setMasterConsolidationKey] = useState(undefined);



    const handelConsolidationDownload = (key) => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/consol/download/?key=${key}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Token ${token}`
            }
        })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "consolidation_data.csv";
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('Failed to fetch master consolidation:', error);
        })
    };

    const handleConsolidationChecked = (event, hub_name, spare) => {
        console.log(hub_name);
        console.log(checkedItemsRef.current);
    console.log(spare);
        if(hub_name in checkedItemsRef.current){
            checkedItemsRef.current[hub_name] = {
                ...checkedItemsRef.current[hub_name],
                [event.target.name]: event.target.checked
            }
        }else{
            checkedItemsRef.current[hub_name] = {
                [event.target.name]: event.target.checked
            }
        }

    }

    const processConsolidations = (hub_name) => {
        setError(false);
        const consolidatedItems = [];
        const targetEtas = [];
        Object.keys(checkedItemsRef.current).forEach((hub) => {
            console.log(hub)
            if(hub === hub_name){
                Object.keys(checkedItemsRef.current[hub]).forEach((spare) => {
                    console.log(spare)
                    if(checkedItemsRef.current[hub][spare]){
                        consolidatedItems.push(spare.split(':')[0]);
                        targetEtas.push(spare.split(':')[1]);
                    }
                })
            }
        })
        if(consolidatedItems.length === 0){
            setError(true);
            setErrorMessage('Please select at least one item to consolidate');
            return;
        }
        const data = {
            origin_name: originName,
            new_location_name:hub_name.split('-')[0],
            mode: hub_name.split('-')[1],
            spares: consolidatedItems,
            target_eta: targetEtas
        }
        console.log(data)
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/consol/0/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            navigate(`/consol/detail/${data.id}`);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    useEffect(() => {
        setLoading(true);
        checkedItemsRef.current = {};
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/locations/?d=y&type=central`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/location/?location_name=${data[0].location_name}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                }).then(response2 => {
                    if(response2.ok){
                        return response2.json();
                    }else{
                        setHubs([]);
                        throw new Error(response2.statusText);
                    }
                })
                .then(data2 => {
                    setMasterConsolidationKey(data2.key);
                    data2.hubs.forEach((hub) => {
                        hub.spares.forEach((spare) => {
                            checkedItemsRef.current[`${hub.hub_name}-${hub.link_description}`] = {
                                ...checkedItemsRef.current[`${hub.hub_name}-${hub.link_description}`],
                                [`${spare.spare_id}:${spare.target_eta}`]: true
                            }
                        })
                    });
                    setHubs(data2.hubs);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                });
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_id}
            })
            setOriginName(newData[0].label);
            setLocations(newData);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [token]);
    const makeAPiCall = (event, newVal) => {
        setLoading(true);
        setOriginName(newVal.label);
        if(!newVal){
            setError(null);
            setHubs([]);
            return;
        }
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/location/?location_name=${newVal.label}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(response.ok){
                return response.json();
            }else{
                throw new Error(response.statusText);
            }
        })
        .then(data => {
            setError(null);
            setMasterConsolidationKey(data.key);
            // add a key target_eta to each spare
            data.hubs.forEach((hub) => {
                hub.spares.forEach((spare) => {
                    checkedItemsRef.current[`${hub.hub_name}-${hub.link_description}`] = {
                        ...checkedItemsRef.current[`${hub.hub_name}-${hub.link_description}`],
                        [`${spare.spare_id}:${spare.target_eta}`]: true
                    }
                })
            });
            setHubs(data.hubs);        
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setError(error.message);
        });
    }
    return (
        loading ? (
            <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
              <Grid item xs={12} m={6} key="error">
                <CircularProgress size={100}/>
              </Grid>
            </Grid>
        ) :(
                <Grid container spacing={2} style={{marginTop:'5px'}}>
                     {error?
                        <Grid item xs={12} sm={12} lg={12}>
                            <CustomAlert error={error} message={errorMessage} disappear={true}/>
                        </Grid>
                        :null
                    }
                    <Grid item xs={12} sm={12} sx={{marginTop:'10px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={locations}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option.label}
                                    value={locations?locations[0]:null}
                                    onChange={makeAPiCall}
                                    renderInput={params =>   
                                        <TextField
                                            {...params}
                                            label="Select Location"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                                    style={{width:'100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                {masterConsolidationKey?
                                    <Button variant="outlined" color="primary" size="large" endIcon={<DownloadIcon />} onClick={()=>handelConsolidationDownload(masterConsolidationKey)} sx={{marginTop:'10px'}}>
                                        Download all consolidations
                                    </Button>
                                :null}
                            </Grid>
                        </Grid>
                    </Grid>
                    {hubs.length?hubs.map(hub=>{
                        return <>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Accordion>
                                        <AccordionSummary
                                        key={`accordion-summary${hub.link_description}`}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-1-content-for-${hub.link_description}`}
                                        id={`panel-1-header-for-${hub.link_description}`}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                                    <Typography sx={{ width: '35%', flexShrink: 0 }}>
                                                        {hub.hub_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                                    {
                                                        hub.link_description==='express'? 
                                                        <Chip label={hub.spares.length > 1? `${hub.spares.length} Express shipments`: `${hub.spares.length} Express shipment`} size="medium" sx={{backgroundColor:"#FFCC00",color:'black',minWidth:'15%'}} variant="outlined"
                                                    />
                                                    :hub.link_description==='standard'?
                                                        <Chip label={hub.spares.length > 1? `${hub.spares.length} Standard shipments`: `${hub.spares.length} Standard shipment`} size="medium" sx={{backgroundColor:"#B5E6A2",color:'black',minWidth:'15%'}} variant="outlined"/>
                                                        :hub.link_description==='urgent'?
                                                            <Chip label={hub.spares.length > 1? `${hub.spares.length} Urgent shipments`: `${hub.spares.length} Urgent shipment`} size="medium" sx={{backgroundColor:"red", color:darkMode?'black':'white', minWidth:'15%'}} variant="outlined"/>
                                                        :<Chip label={hub.spares.length > 1? `${hub.spares.length} items`: `${hub.spares.length} item`} size="medium" sx={{backgroundColor:"#CCECFF", color:'black', minWidth:'15%'}} variant="outlined"/>
                                                    }
                                                </Grid>

                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                            {hub.link_description!=='urgent'?
                                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                                        <Tooltip title="Creates a grouping of individual shipments into one large consolidated shipment.Please select the items to include or exclude" arrow placement="top">
                                                            <Button  variant="contained" color="primary" size="large" sx={{ marginleft:'50px',marginBottom:'1%' }} onClick={()=>processConsolidations(`${hub.hub_name}-${hub.link_description}`)}>
                                                                    Process
                                                            </Button>
                                                        </Tooltip>
                                                </Grid>
                                                :null}
                                                <Grid item xs={12} sm={12} md={2} lg={2}>

                                                    <Button variant="outlined" color="primary" size="large" endIcon={<DownloadIcon />} onClick={()=>handelConsolidationDownload(hub.key)} sx={{marginBottom:'1%'}}>
                                                            Download consolidations
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                          {hub.spares.map((item, index) => {
                                                return  <Paper
                                                    key={`${index}`}
                                                    elevation={3}
                                                    style={{ padding: '20px', margin: '20px 0', boxShadow: `0 0 15px` }}
                                                >
                                                <Grid container spacing={2}>
                                                        {item.plausible?null:(
                                                            <Grid item xs={12}>
                                                                <IconButton color="warning">
                                                                    <WarningIcon />
                                                                    <Typography variant="body2" component="h6" color="warning">Current ETA implausable</Typography>
                                                                </IconButton>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                                            <AddBusinessIcon fontSize="small" color="primary" />
                                                            <Typography variant="body2" component="h2" >
                                                            Type: {item.link_description}
                                                            </Typography>
                                                            <Typography variant="body2" component="h2" >
                                                                {`Port name: ${item.port_name}` }
                                                            </Typography>
                                                            <Typography variant="body2" component="h2" >
                                                                {`Delivery description: ${item.delivery_description}` }
                                                            </Typography>
                                                            <Typography variant="body2" component="h2" >
                                                                {`Cost: ${item.currency_symbol} ${item.logistics_cost > 0? Math.round(item.logistics_cost): ''}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <ConstructionIcon fontSize="small" color="primary" />
                                                            <Typography variant="body1" fontWeight={'bold'}>
                                                                    Spare id: {item.spare_id}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                    Part Description: {item.description}
                                                            </Typography>
                                                            <Typography variant="body2" >
                                                                    Status: {item.status}
                                                            </Typography>
                                                            <Link to={`/part/${item.spare_id}`}>
                                                                <Button variant="contained" color="primary" size="small">
                                                                    More information
                                                                </Button>
                                                            </Link>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <DirectionsBoatIcon fontSize="small" color="primary" />
                                                            <Typography variant="body2" component="h2" fontWeight={'bold'}>
                                                            Shipname: {`${item.ship_name}(${item.imo})`}
                                                            </Typography>
                                                            <Typography variant="body2" component="h2">
                                                            Ship type: {`${item.ship_type.replace(/_/g, ' ')}`}
                                                            </Typography>
                                                            <Typography variant="body2" component="h2" >
                                                            Destination: {item.port_name}
                                                            </Typography>
                        
                                                            <Button component={Link} to={`/schedule/${item.imo}`} variant="contained" color="primary" size="small">
                                                                Destinations
                                                            </Button>
                      
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                                            <FormControlLabel control={<Checkbox name={`${item.spare_id}:${item.target_eta}`} defaultChecked={true} onChange={(event)=>{
                                                                handleConsolidationChecked(event, `${hub.hub_name}-${hub.link_description}`, item);
                                                            }} checked={checkedItemsRef[item.spare_id]}/>} label="Consolidate" />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                          })}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            <Divider />
                        </>
                    }):null}
                </Grid>
        )
            
    )
}
