/**
 * ColumnHeaders component represents user settings for a table.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.token - The authentication token for API requests.
 * @param {boolean} [props.update=true] - Flag to determine if the update button should be shown.
 * @param {Function} props.callback - Callback function to update the column values for a parent component.
 * 
 * @returns {JSX.Element} The rendered ColumnHeaders component.
 */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {Grid} from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Input } from '@mui/material';
import { FormHelperText } from '@mui/material';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import CustomAlert from './Alert';



export default function ColumnHeaders({token, update=true, callback}) {
    const [columns, setColumns] = useState([]);
    const [error , setError] = useState(null);
    const [errorMessage , setErrorMessage] = useState('');
    const [success , setSuccess] = useState(null);
    const[successMessage , setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/cols/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'applicationn',
              'authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setColumns(data);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Failed to fetch data:', error);
            setLoading(false);
        })
      }, [token]);
    
      const handelChange = (event, index) => {
        // find the column that is being changed
        const filteredColumn = columns.filter((col, i) => i === index);
        if(filteredColumn.length){
            let data = filteredColumn[0];
            data.value = event.target.value;
            const otherColumns = columns.filter((col, i) => i !== index);
            // change it and insert it back
            otherColumns.splice(index, 0, data);
            // otherColumns.push(data);
            setColumns(otherColumns);
        }
        callback(columns);
      }
      const handelSubmit = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/fleet/cols/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Token ${token}`
            },
            body: JSON.stringify(columns)
        })
        .then(response => {
            if(response.status === 400){
                setError(true);
                setErrorMessage('Invalid data');
            }
            setLoading(false);
            return response.json();
        })
        .then(data => {
            setSuccess(true);
            setSuccessMessage('Columns update successfully');
        })
        .catch((error) => {
            console.error('Failed to fetch data:', error);
            setLoading(false);
        })
      }
    return (
        <Grid item xs={12} sm={12} sx={{marginLeft:'10px'}}>
            {loading?<CircularProgress/>:
                <>
                        {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                        {error ? <CustomAlert error message={errorMessage} disappear={false}/>:null}
                        {columns.length?
            
                            columns.map((col, index) => {
                            return (
                                <FormControl key={index} fullWidth variant="standard" sx={{marginTop:'20px'}}>
                                    <InputLabel htmlFor={`${col.column_name}`}>{col.column_name}</InputLabel>
                                    <Input id={`${col.column_name}`} type={`${col.type?col.type:'text'}`} aria-describedby={`${col.column_name}`} value={`${col.value}`} disabled={!col.editable} onChange={(event)=>{handelChange(event, index)}}/>
                                    <FormHelperText id={`${col.column_name}`}>{col.help_text?col.help_text:''}</FormHelperText>
                                </FormControl>
                            )
                            })
                        
                        :null}
                        {update?<Button variant="contained" color="primary" sx={{marginTop:'20px'}} onClick={handelSubmit}>Update</Button>:null}
                </>
            }
        </Grid>
    );
}