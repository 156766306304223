import React, { useCallback, useEffect } from 'react';
import {Paper, Box} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Button from '@mui/material/Button';




export default function LocationStatus ({token, darkMode}) {
    const [locations, setLocations] = React.useState([]);
    const [selectedHub , setSelectedHub] = React.useState(null);
    const [arriving, setArriving] = React.useState([]);
    const [at_approach, setAtApproach] = React.useState([]);
    const [at_facility, setAtFacility] = React.useState([]);
    const [inPort , setInPort] = React.useState([]);

    const makeAPiCall = useCallback((event, value) => {
        setSelectedHub(value);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/location/status/${value.label}/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setArriving(data.arriving);
            setAtApproach(data.at_approach);
            setAtFacility(data.at_facility);
            setInPort(data.in_port);
        })
        .catch(err => console.log(err))
    }, [token]);

    const VesselsDisplay = (index, item, arrival=undefined) => {
        return (
            <>
                <ListItem  key={`${index} - ${item.imo}-item`}>
                    <ListItemAvatar key={`${index} - ${item.imo}-avatar`}>
                        <Avatar>
                            <DirectionsBoatIcon key={`${index} - ${item.imo}-icon`}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`IMO: ${item.imo}( ${item.ship_name} )`} secondary={`Dwt: ${item.dwt}`}/>
                </ListItem>
                <ListItem key={`${index} - ${item.facility_name}`} >
                    {arrival?
                        <ListItemText primary={`Expected Arrival: ${item.ais_eta}`} secondary={`Status: ${item.status}`} />
                
                    :<ListItemText primary={`Expected Arrival: ${item.ata}`} />}
                </ListItem>
                {item.facility_name?
                    <ListItem key={`${index} - ${item.facility_name}`}>
                        <ListItemText secondary={`Arrivided at ${item.facility_name}: ${item.ata}`} />
                    </ListItem>:null
                }
                <ListItem key={`${index} - spare-status`}>
                        <ListItemText secondary={`Spare status: ${item.spares_by_status.length > 0? item.spares_by_status:"None"}`} />
                </ListItem>
                <ListItem >
                    <Box key={`${index} - ${item.imo}-box`}>
                        <Link to={`/ship/detail/${item.imo}`}>
                            <Button variant="contained" color="primary" size="small">
                                Details....
                            </Button>
                        </Link>
                    </Box>
                </ListItem>
            </>
        )
    }

    const vesselDisplayContainer= (data, label, noDataLabel, arrival=false) => {
        return (
            <Grid item xs={12} sm={12}>
                <Box sx={{textAlign:'left', marginLeft:'20px', fontSize:'2rem', textDecoration:'underline'}}>{label} </Box>
                <List sx={{ width: '100%', display:'flex', marginTop:'12px',marginLeft:'10px', flexDirection:{
                            xs:'column',
                            sm:'column',
                            custom:'column',
                            surface:'column',
                            md:'column',
                            lg:'column',
                        }}}>
                        {data.length > 0 ?data.map((item, index) => {
                            return ( VesselsDisplay(index, item, arrival))
                        }):noVesselText(noDataLabel)}
                </List>
            </Grid>
        )
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/locations/?d=y`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_name}
            })
            setLocations(newData);
            setSelectedHub(newData[0]);
            makeAPiCall(null, newData[0]);
        })
        .catch(err => console.log(err))

    }
    , [token, makeAPiCall]);

    const noVesselText = (text) => {
        return (
            <Paper sx={{textAlign:'left', padding:'50px', fontSize:'2rem'}}>{text} </Paper>
        )
    }



    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginTop:'50px'}}>
                <Autocomplete
                    disablePortal
                    id="port-locations"
                    options={locations}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option.label}
                    onChange={makeAPiCall}
                    value={selectedHub}
                    renderInput={params =>   
                        <TextField
                            {...params}
                            label="Select a location"
                            variant="outlined"
                            fullWidth
                        />
                    }

                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                    style={{width:'100%'}}
                />

            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                {vesselDisplayContainer(arriving, `Arriving in ${selectedHub?selectedHub.label:''}`, `No vessels arriving in ${selectedHub?selectedHub.label:''}`, true)}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                {vesselDisplayContainer(at_approach, `At Approach in ${selectedHub?selectedHub.label:''}`, `No vessels at approach in ${selectedHub?selectedHub.label:''}`)}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                {vesselDisplayContainer(at_facility, `At a Facility in ${selectedHub?selectedHub.label:''}`, `No vessels at a facility in ${selectedHub?selectedHub.label:''}`)}
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                {vesselDisplayContainer(inPort, `In Port in ${selectedHub?selectedHub.label:''}`, `No vessels in port in ${selectedHub?selectedHub.label:''}`)}
            </Grid>
        </Grid>
    );
}